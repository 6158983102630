body.active-modal {
    overflow: hidden;
}

.Modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background-color: rgba(41, 38, 38, 0.671);
}

.modal-content {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 1200px;
    min-width: 300px;
}

.modal-content p {
    font-size: 1.07rem;
    font-weight: 400;
}

.modal-content h1,
.modal-content h2 {
    font-family: 'Nunito', sans-serif;
}

.close-button {
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
}

.modal-content button {
    font-family: 'Nunito', sans-serif;
    position: absolute;
    margin: 10px;
    padding: 8px 16px;
    background-color: #d90429;
    color: white;
    border: none;
    cursor: pointer;
}