.main-body-container {
    background-color: white;
    max-width: 100%;
    margin: 0;
}

p {
    font-family: 'Albert Sans', sans-serif;
    font-weight: 200;
}

a {
    font-family: 'Albert Sans', sans-serif;
    font-weight: 200;
}

h3 {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
}

.carousel {
    /* margin-bottom: 4rem; */
    /* min-height: 100vh; */
}




.carousel-caption {
    text-align: left;
    top: 200px;
    z-index: 5;
    max-width: 700px;
}

.carousel-caption.text-start-2 {
    top: 250px;
}

.carousel-caption.text-start-3 {
    top: 300px;
}

.carousel-indicators {
    display: none;
}

.carousel-caption p {
    font-weight: 400;
    font-size: 20px;
}

.carousel-caption h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 4rem;
    font-weight: 600;
}

.bd-1-img {
    background-image: url("./../images/bannerImg/2.jpg");
    background-color: #2b2d42;
    background-size: cover;
    height: 100vh;
}

.bd-2-img {
    background-image: url("./../images/bannerImg/1.jpg");
    background-color: #2b2d42;
    background-size: cover;
    height: 100vh;
}

.bd-3-img {
    background-image: url("./../images/bannerImg/3.jpg");
    background-color: #2b2d42;
    background-size: cover;
    height: 100vh;
}











.operations {
    margin-top: 70px;
    display: flex;
    justify-content: space-around;
}

.operations-container {
    width: 100%;
    max-height: 85vh;
}

.operations-container button {
    font-family: 'Nunito', sans-serif;
}

.operations-pic {
    background-image: url("./../images/lukasz-rawa-lUSYREUSXBg-unsplash.jpg");
    background-size: cover;
    position: relative;
    z-index: 5;
    bottom: 8rem;
    width: 45%;
    height: 115svh;
}

.operations-pic.under {
    background-image: url("./../images/under.png");
    height: 90svh;
    bottom: 50px;
}

.operations-text {
    padding : 50px;
    width: 40%;
}

.operations-text p {
    font-weight: 400;
    font-size: 20px;
}

.operations-text ul li {
    list-style-type: disc;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    line-height: 2rem;
}

.operations-h1 {
    font-family: 'Nunito', sans-serif;
}


.what-we-offer {
    padding: 2rem;
    background-color: #edf2f4;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}

.card-container {
    background-color: #edf2f4;
    color: #2b2d42;
    width: 100%;
    /* padding: 30px 100px 100px 100px; */
}

.cards {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 550px;
    width: 48%;
    padding: 0 30px;
}

.cards:hover {
    box-shadow: rgba(114, 117, 117, 0.144) 0px 8px 24px;
}

.card-list-1,
.card-list-2 {
    display: flex;
    justify-content: space-evenly;
}


.card-header {
    font-family: 'Nunito', sans-serif;
    background-color: #edf2f4;
    font-size: 70px;
    margin: 200px 0 100px 0;
    text-align: center;
}

.cards h3 {
    font-size: 2rem;
    color: #d90429;
}

.cards p {
    font-weight: 400;
    font-size: 19px;
}

.card-image {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #2b2d42;
    margin: 50px;
}

.card-image-1 {
    background-image: url("./../images/digital.png");
    background-size: cover;
}

.card-image-2 {
    background-image: url("./../images/financial.png");
    background-size: cover;
}

.card-image-3 {
    background-image: url("./../images/productDes.png");
    background-size: cover;
}

.card-image-4 {
    background-image: url("./../images/strategy.png");
    background-size: cover;
}

.btn.btn-lg.btn-primary {
    text-transform: uppercase;
    border-radius: 0;
    background-color: rgb(163, 20, 20);
    border: none;
}


.fixed-background {
    background-image: url("./../images/traditional-turkish-musical-instruments_header.jpg");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 40vh;
    background-color: #2b2d42;
}


@media screen and (max-width: 767px) {

    .bd-1-img,
    .bd-2-img,
    .bd-3-img {
        height: 90vh;
        background-image: none;
        background-color: #2b2d42;
    }

    .main-body-container {
        margin: 0;
        width: 100%;
    }

    .carousel {
        height: 100vh;
    }

    .carousel-item {
        height: 100vh;
    }

    .carousel-caption {
        margin: 0;
        padding: 0;
        top: 70px;
    }

    .carousel-caption.text-start-2 {
        top: 150px;
    }

    .carousel-caption.text-start-3 {
        top: 200px;
    }

    .carousel-caption h1 {
        font-size: 2.5rem;
    }

    .operations-container {
        padding: 4px;
        margin: 0;
        min-height: 150vh;
    }

    .operations {
        height: 100%;
        position: static;
        padding: 0;
        margin: 0;
        display: block;
    }

    .operations-pic {
        position: static;
        background-size: cover;
        padding: 0;
        margin: 1rem auto;
        width: 80%;
        height: 400px;
    }

    .operations-text {
        padding: 50px;
        width: 100%;
        height: 100%;
    }

    .operations-text p {
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: 20px;
    }

    .operations-h1 {
        font-size: 40px;
    }

    .operations-text ul li {
        font-size: 16px;
    }

    .what-we-offer {
        clip-path: none;
    }

    .card-header {
        margin: 20px;
        font-size: 40px;
    }

    .card-list-1,
    .card-list-2 {
        display: block;
    }

    .cards {
        margin: 3rem auto;
        height: 650px;
    }

    .cards h3 {
        font-size: 30px;
    }

    .cards p {
        font-size: 17px;
    }

    .card-image {
        width: 100px;
        height: 100px;
    }

    .team-container {
        flex-direction: column-reverse;
    }

    .teams {
        margin: 1rem auto;
        width: 100%;
    }

    .team-header-container {
        margin: 0;
        height: 100%;
        width: 100%;
    }

    .team-media-scroller {
        grid-auto-columns: 30%;
        gap: 10rem;
    }

    .team-text {
        width: 200px;
    }

}