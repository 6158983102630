.main-nav {
    position: fixed;
    z-index: 10;
    width: 100%;
}


.navbar.navbar-expand-lg {
    padding: 25px;
    border-bottom: 1px solid rgba(163, 158, 158, 0.212);
    background: white;
    /* background: rgba(255, 255, 255, 0.089);
    backdrop-filter: blur(10px); */
    padding: 10px;
    font-family: 'Nunito', sans-serif;
}

.nav-item {
    text-align: center;
    font-size: 17px;
    width: 100px;
    border-radius: 3px;
}


.top-nav {
    background-color: white;
    height: 20px;
    padding: 0 100px;
}

.top-navv {
    display: flex;
    font-size: 0.8rem;
}
.top-nav div {
    margin: 0 25px;
}

/* .second-nav {
    height: 60px;
} */

@media screen and (max-width: 767px) {
    .main-nav {
        position: static;
    }

    .top-nav {
        display: none;
    }


}
