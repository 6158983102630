.about-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-banner {
    background-color: #2b2d42;
    background-image: url("./paul-zoetemeijer-ekBOf6sJYYo-unsplash.jpg");
    width: 100%;
    height: 65vh;
    background-size: cover;
    position: relative;
}



.about-header {
    color: white;
    font-size: 5rem;
    position: absolute;
    bottom: 30%;
    left: 8%;
}

.about-text-container {
    color: black;
    border: 1px solid white;
}

.about-text {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    height: 100%;
    margin: 100px;
}

.about-text h1 {
    color: #2b2d42;
    text-align: left;
    font-size: 50px;
    font-weight: 600;
}



.industries {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.industry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    height: 40px;
    width: 40%;
    margin: 0 auto;
    line-height: -10px;
}

.industry:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}


.fixed-background2 {
    background-image: url("./../images/traditional-turkish-musical-instruments_header.jpg");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 40vh;
    background-color: #2b2d42;
}


/* .staff-container {
    position: relative;
} */





.clients-container {
    height: 45vh;
}

.clients-container h1, .team-container h1 {
    color: #2b2d42;
    font-family: 'Nunito', sans-serif;
    font-size: 3.5rem;
    font-weight: 600;
    margin: 6rem 0 2rem 100px;
}

.slider {
    display: inline-block;
    padding: 60px 0;
    animation: slider 50s infinite linear;
}

.logos:hover .slider {
    animation-play-state: paused;
}

.logos {
    white-space: nowrap;
}


@keyframes slider {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-160%);
    }
}

.slider img {
    margin: 0 40px;
    height: 200px;
}



.team-container {
    clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    background-color: #edf2f4;
    width: 100%;
    height: 100%;
    padding: 49px;
    margin-top: 50px;
}

.team-container .p {
    margin: 2rem 6rem;
    font-weight: 400;
    font-size: 1.2rem;
}

.teams {
    margin: 20px;
}

.team {
    background-color: white;
    width: 500px;
    height: 600px;
    border-radius: 8px;
}

.team:hover {
    box-shadow: rgba(114, 117, 117, 0.144) 0px 8px 24px;
}

.team-pic {
    margin: 1rem auto;
    width: 60%;
    /* background-color: #2b2d42; */
    height: 400px;
}

.team-1 {
    background-image: url("https://i.imgur.com/V9YRVMp.jpg");
    background-size: cover;
    background-position: top;
}

.team-2 {
    background-image: url("./../images/IMG-20230810-WA0005.jpg");
    background-size: cover;
}

.team-3 {
    background-image: url("./../images/image.png");
    background-size: cover;
}

.team-4 {
    background-image: url("./../images/IMG-20230811-WA0010.jpg");
    background-size: cover;
}

.team-media-scroller {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}


.team-header-container {
    padding: 0 50px;
    width: 50%;
    height: 480px;
    background-color: rgb(102, 89, 89);
}

.team-text {
    margin: 2rem 6rem;
}

.team-text h5 {
    margin-top: 10px;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.team-text p {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 17px;
}

